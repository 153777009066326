<template>
  <div class="phone">
    <img :src="image">
    <div class="phone-display">
      <div class="phone-date">
        Today 10:00
      </div>
      <div class="phone-text">
        {{ smsText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Create',

  props: {
    image: {
      type: String,
      default: '',
    },
    smsText: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.phone {
  max-width: 200px;
  min-width: 140px;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.1));
  position: sticky;
  top: calc(70px + 83px + 20px);
  // z-index: 5;
  img {
    width: 100%;
  }
  &-display {
    position: absolute;
    top: 71px;
    left: 30px;
    width: 140px;
    height: 220px;
    background-color: #fff;
    padding: 5px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &-date {
    font-size: 7px;
    text-align: center;
    font-family: San-Francisco, Arial, Helvetica, sans-serif;
    font-weight: 600;
    margin-bottom: 8px;
    color: #848a93;
  }
  &-text {
    // position: absolute;
    // top: 90px;
    // left: 35px;
    width: max-content;
    max-width: 120px;
    padding: 6px 8px;
    border-radius: 8px;
    font-size: 10px;
    font-family: San-Francisco, sans-serif;
    background-color: #f2f3f7;
    font-weight: 600;
    color: #848a93;
    line-height: 13px;
    word-wrap: break-word;
    // word-break: break-all;
  }
}
</style>
