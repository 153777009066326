<template>
  <b-card>
    <h1 class="fs-20">
      Смс юбориш
    </h1>
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <b-form class="auth-login-form mt-2">
        <div class="row">
          <div class="col-6">
            <b-form-group
              :label="$t('SMS shablon')"
              label-for="sms_template_id"
            >
              <validation-provider
                #default="{ errors }"
                name="sms_template_id"
                rules="required"
              >
                <b-form-select
                  id="sms_template_id"
                  v-model="form.sms_template_id"
                  :state="errors.length > 0 ? false : null"
                  name="name"
                  value-field="id"
                  text-field="title_uz"
                  :placeholder="$t('SMS shablonni tanlang')"
                  :options="smsTemps.data"
                  @input="smsSelected"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              :label="$t('Yuborish turi')"
              label-for="send_for"
            >
              <validation-provider
                #default="{ errors }"
                name="send_for"
                rules="required"
              >
                <b-form-select
                  id="send_for"
                  v-model="form.send_for"
                  :state="errors.length > 0 ? false : null"
                  name="name"
                  value-field="id"
                  text-field="name"
                  :placeholder="$t('Yuborish turini tanlang')"
                  :options="sentTypes"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="col-12">
            <b-form-group
              v-if="form.send_for == 'personal'"
              label="SMS yuboruvchi mijozlarni tanlang"
              label-for="tag"
            >
              <validation-provider
                #default="{ errors }"
                name="client_ids"
                rules="required"
              >
                <v-select
                  id="tag"
                  v-model="client_objs"
                  multiple
                  :loading="searchLoading"
                  placeholder="Mijozlarni tanlang"
                  :close-on-select="false"
                  :options="clientsList"
                  :state="errors.length > 0 ? false : null"
                  :get-option-label="option => option.lastname +' ' + option.firstname + ' ' +option.middlename + ' (' + option.doc_series + '' + option.doc_number+')'"
                  @search="searchProduct"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    <span>
                      {{ option.lastname +' ' + option.firstname + ' ' +option.middlename }}
                    </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <hr class="mt-3">
          <template v-if="smsText">
            <div class="col-4">
              <VPhone
                :sms-text="smsText"
                class="phone"
                image="/images/phone-sms-en.png"
              />

            </div>
            <div
              class="col-8"
            >
              <p class="fs18">
                <b>SMS matni: </b> {{ smsText }}
              </p>
            </div>
          </template>
        </div>
      </b-form>
    </validation-observer>
    <b-button
      variant="primary"
      class="float-right"
      size="small"
      @click="save"
    >
      <feather-icon icon="SendIcon" />
      Yuborish
    </b-button>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import vSelect from 'vue-select'
import VPhone from '@/components/VPhone.vue'
import { debounce } from 'debounce'

export default {
  name: 'Create',
  components: {
    VPhone,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      form: {
        send_for: '',
        sms_template_id: null,
        client_ids: null,
      },
      filterModel: {
        per_page: 999,
      },
      searchLoading: false,
      roles: [],
      clientsList: [],
      client_objs: [],
      smsText: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      title: '',
      visible: false,
      required,
      sentTypes: [
        { id: 'personal', name: 'Tanlanganlarga yuborish' },
        { id: 'active_contracts', name: 'Aktiv shartnomasi bor mijozlarga yuborish' },
        { id: 'all_contracts', name: 'Barchaga shartnoma tuzilgan(aktiv, bekor qilingan, arxiv) mijozlarga yuborish' },
        { id: 'overdue_contracts', name: 'To`lov muddati o`tgan mijozlarga yuborish' },
        { id: 'closed_contracts', name: 'Shartnomasi yopilgan mijozlarga yuborish' },
        { id: 'all', name: 'Barcha mijozlarga(shartnoma tuzilgan, tuzilmagan) yuborish' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      smsTemps: 'sms/GET_ITEMS',
    }),
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    customLabel() {
      return option => `${option.lastname}, ${option.firstname} ${option.middlename}`
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
        }, 200)
      }
    },
  },
  mounted() {
    this.title = this.$t('Смс юбориш')
    this.$root.$on('add', data => {
      this.visible = true
    })
    this.fetchOriginalClients().then(res => {
      this.clientsList = res.data.data
    })
    this.getItems()
  },
  methods: {
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel })
      this.loading = false
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    async save() {
      const valid = await this.validationForm()
      if (valid && this.hasPermission('sms.send-sms-messages')) {
        if (this.client_objs.length) {
          this.form.client_ids = this.client_objs.map(el => el.id)
        }
        this.method(this.form)
          .then(res => {
            showToast(
              'success',
              this.$t('Успешно отправлено'),
              'CheckCircleIcon',
            )
            this.confirmText()
          })
          .catch(err => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors)
            }
            showToast('danger', this.$t('Ошибка'), 'XIcon')
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    confirmText() {
      this.$swal({
        title: 'SMS muvaffaqqiyatli yuborildi',
        text: 'Yana SMS yuborasizmi?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Ha, yuboraman',
        cancelButtonText: 'Yo`q, bosh sahifaga qaytish',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        console.log(result)
        if (result.value) {
          window.location.reload()
        } else {
          this.goTo('ScanerProduct')
        }
      }).catch(() => {
      })
    },
    edit(item) {
      this.form.id = item.id
      this.form.title_uz = item.title_uz
      this.form.body_uz = item.body_uz
      this.visible = true
    },
    method(data) {
      if (this.form.id) return this.updateItem(data)
      return this.sendSmsMessages(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    smsSelected(e) {
      const text = this.smsTemps.data.find(el => el.id == e)
      this.smsText = text.body_uz
    },
    ...mapActions({
      getItemsAction: 'sms/index',
      sendSmsMessages: 'sms/sendSmsMessages',
      fetchOriginalClients: 'client/originalClients',
      updateItem: 'sms/update',
    }),

    searchProduct: debounce(function (search) {
      this.searchLoading = true
      if (search && search.length >= 3) {
        this.fetchOriginalClients({ search }).then(res => {
          this.clientsList = res.data.data
        })
          .finally(() => { this.searchLoading = false })
      } else {
        this.fetchOriginalClients().then(res => {
          this.clientsList = res.data.data
        })
          .finally(() => { this.searchLoading = false })
      }
    }, 500),
  },
}
</script>

<style scoped></style>
