var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h1',{staticClass:"fs-20"},[_vm._v(" Смс юбориш ")]),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('SMS shablon'),"label-for":"sms_template_id"}},[_c('validation-provider',{attrs:{"name":"sms_template_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"sms_template_id","state":errors.length > 0 ? false : null,"name":"name","value-field":"id","text-field":"title_uz","placeholder":_vm.$t('SMS shablonni tanlang'),"options":_vm.smsTemps.data},on:{"input":_vm.smsSelected},model:{value:(_vm.form.sms_template_id),callback:function ($$v) {_vm.$set(_vm.form, "sms_template_id", $$v)},expression:"form.sms_template_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('Yuborish turi'),"label-for":"send_for"}},[_c('validation-provider',{attrs:{"name":"send_for","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"send_for","state":errors.length > 0 ? false : null,"name":"name","value-field":"id","text-field":"name","placeholder":_vm.$t('Yuborish turini tanlang'),"options":_vm.sentTypes},model:{value:(_vm.form.send_for),callback:function ($$v) {_vm.$set(_vm.form, "send_for", $$v)},expression:"form.send_for"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-12"},[(_vm.form.send_for == 'personal')?_c('b-form-group',{attrs:{"label":"SMS yuboruvchi mijozlarni tanlang","label-for":"tag"}},[_c('validation-provider',{attrs:{"name":"client_ids","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"tag","multiple":"","loading":_vm.searchLoading,"placeholder":"Mijozlarni tanlang","close-on-select":false,"options":_vm.clientsList,"state":errors.length > 0 ? false : null,"get-option-label":function (option) { return option.lastname +' ' + option.firstname + ' ' +option.middlename + ' (' + option.doc_series + '' + option.doc_number+')'; }},on:{"search":_vm.searchProduct},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',[_vm._v(" "+_vm._s(option.lastname +' ' + option.firstname + ' ' +option.middlename)+" ")])]}}],null,true),model:{value:(_vm.client_objs),callback:function ($$v) {_vm.client_objs=$$v},expression:"client_objs"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),_c('hr',{staticClass:"mt-3"}),(_vm.smsText)?[_c('div',{staticClass:"col-4"},[_c('VPhone',{staticClass:"phone",attrs:{"sms-text":_vm.smsText,"image":"/images/phone-sms-en.png"}})],1),_c('div',{staticClass:"col-8"},[_c('p',{staticClass:"fs18"},[_c('b',[_vm._v("SMS matni: ")]),_vm._v(" "+_vm._s(_vm.smsText)+" ")])])]:_vm._e()],2)])]}}])}),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","size":"small"},on:{"click":_vm.save}},[_c('feather-icon',{attrs:{"icon":"SendIcon"}}),_vm._v(" Yuborish ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }